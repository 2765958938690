<template>
  <div>
    <img
      class="basket-type-image"
      v-if="position === 'Front left chest' || position === 1"
      src="../../assets/icons/position_front_top_left.svg"
      alt="" />
    <img
      class="basket-type-image"
      v-if="position === 'Front right chest'  || position === 2"
      src="../../assets/icons/position_front_top_right.svg"
      alt="" />
    <img
      class="basket-type-image"
      v-if="position === 'Front center'  || position === 3"
      src="../../assets/icons/position_front_centre.svg"
      alt="" />
    <img
      class="basket-type-image"
      v-if="position === 'Sleeve left' || position === 4"
      src="../../assets/icons/position_left_sleeve.svg"
      alt="" />
    <img
      class="basket-type-image"
      v-if="position === 'Sleeve right' || position === 5"
      src="../../assets/icons/position_right_sleeve.svg"
      alt="" />
    <img
      class="basket-type-image"
      v-if="position === 'Back neck' || position === 6"
      src="../../assets/icons/position_back_neck.svg"
      alt="" />
    <img
      class="basket-type-image"
      v-if="position === 'Back center' || position === 7"
      src="../../assets/icons/position_back_centre.svg"
      alt="" />
</div>
</template>

<script>
export default {
  name: 'BasketPoisitionImage',
  props: [
    'position',
  ],
};
</script>
