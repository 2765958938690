<template>
  <div class="grid-container">
    <div class="profile">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center relative">
          <h1 class="logo-text pt-5">
            Customised <span>Clothing</span>
          </h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center">
          <h3 class="pt-5">
            Previous orders
          </h3>
        </div>
      </div>
      <div
        v-for="(order, index) in orders"
        :key="index"
        class="bordered pa-3 mb-5">
        <div class="grid-x">
          <div class="cell small-6 text-blue text-heavy">
            Date: {{ order.created | tinyDate }}
          </div>
          <div class="cell small-3 pr-1 text-blue">
            <router-link :to="`/invoice/${order.id}`">
              <button class="button small rounded rounded-small blue expanded">Invoice</button>
            </router-link>
          </div>
          <div class="cell small-3">
            <button
              @click="reorder(index)"
              class="button small rounded rounded-small blue expanded">
              Re-order
            </button>
          </div>
        </div>
        <div class="grid-x">
          <div class="cell small-6 text-blue text-heavy">
            Order no: {{ order.id }}
          </div>
        </div>
        <div class="grid-x">
          <div class="cell small-6 text-green text-heavy">
            {{ order.totals | priceInPounds | currency }}
          </div>
        </div>
        <div class="grid-x grid-margin-x mt-3">
          <div
            v-for="(item, gindex) in order.garments"
            :key="gindex"
            class="cell small-4 re-order-product text-center mb-2">
            <span class="text-blue text-heavy text-small">
              {{ item.quantity }} x {{ item.garment_colour_size.garment.short_name }}
            </span>
          </div>
        </div>
        <div class="grid-x grid-margin-x mt-3">
          <div
            v-for="(design, dindex) in order.designs"
            :key="dindex"
            class="cell small-6">
            <div class="grid-x">
              <div class="cell small-4 pa-1">
                <div class="bg-blue text-center pa-1 fixed-height">
                  <img
                    style="width: 85%"
                    v-if="design.design.type === 1"
                    src="../assets/icons/green_print.svg"
                    alt="" />
                  <img
                    v-else
                    class="bg-white icon-bg"
                    src="../assets/icons/red_embroidery.svg"
                    alt="" />
                </div>
              </div>
              <div class="cell small-4 pa-1">
                <div class="bg-blue text-center pa-1 fixed-height">
                  <app-position-image :position="design.design.position" />
                </div>
              </div>
              <div class="cell small-4 pa-1">
                <div class="bg-blue text-center pa-1 fixed-height relative">
                  <span
                    class="text-heavy text-green text-small the-size">
                    {{ design.size }}
                  </span>
                  <img
                    src="../assets/icons/double_arrow.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import positionImage from '@/components/basket/PositionImage.vue';
import axios from '../axios';

export default {
  name: 'PreviousOrders',
  data() {
    return {
      orders: [],
      total: 0,
    };
  },
  components: {
    appPositionImage: positionImage,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    reorder(index) {
      const orderId = this.orders[index].id;
      const basket = this.$store.state.basketId;
      axios.get(`/baskets/reorder/${orderId}/${basket}.json?token=${this.token}`)
        .then((response) => {
          this.$store.commit('basketTotal', response.data.basketTotal);
          this.$router.push('/basket');
        });
    },
    getOrders() {
      axios.get(`/orders/get/${this.$store.state.userid}.json?token=${this.token}`)
        .then((response) => {
          this.orders = response.data.orders;
          this.total = response.data.totals;
        });
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<style lang="scss">
  .re-order-product {
    border: 2px solid #29287c;
  }
  .fix-height {
    min-height: 45px;
  }
  .size-text {
    display: block;
    position: absolute;
    top: -5px;
  }
  .fixed-height {
    height: 40px;
  }

  @media all and (min-width: 400px) {
    .fixed-height { height: 45px; }
  }
  @media all and (min-width: 500px) {
    .fixed-height { height: 55px; }
  }
  @media all and (min-width: 550px) {
    .fixed-height { height: 65px; }
  }
  @media all and (min-width: 650px) {
    .fixed-height { height: 78px; }
  }
  @media all and (min-width: 750px) {
    .fixed-height { height: 90px; }
  }
  @media all and (min-width: 850px) {
    .fixed-height { height: 110px; }
  }

  .the-size {
    display: block;
    text-align: center;
    padding-top: 2px;
    line-height: 1;
  }
</style>
